import React from "react";
import s from "./style.module.css";
import cn from "classnames";
import { useInView } from "react-intersection-observer";
import t from "../../typography.module.css";

export const Row = ({ items, max, colors, text, isBelow, hideNums, small }) => (
  <div className={cn(s.bar, { [s.small]: small })}>
    {items.map(({ category, value }, i, arr) => (
      <div
        key={i}
        className={cn(s.section, {
          [s.isMale]: category.toLowerCase().includes("male"),
        })}
        style={{
          flex: value / max,
          "--color": colors[i],
        }}
      >
        <div className={s.bg}></div>
        <span
          className={cn(s.sectionLabel, { [s.sectionLabelBelow]: isBelow })}
        >
          <span className={s.sectionLabelText}>
            {!hideNums && (
              <span className={cn(s.sectionNum, t.xs)}>{value}%</span>
            )}
            {text[category]}
          </span>
        </span>
      </div>
    ))}
  </div>
);

const Stacked = ({ title, top = [], bottom = [], colors, text, max }) => {
  const { ref, inView } = useInView({
    threshold: 0.7,
    delay: 30,
    triggerOnce: true,
  });

  const calcMax = max || top.reduce((total, a) => a.value + total, 0);

  return (
    <div
      ref={ref}
      data-inview={inView}
      className={cn(s.wrap, { [s.hasTop]: top.length > 0 })}
    >
      {title && <h3 className={cn(s.title, t.m)}>{title}</h3>}

      <div>
        {top.length > 0 && (
          <Row items={top} max={calcMax} text={text} colors={colors.top} />
        )}

        {bottom.length > 0 && (
          <Row
            items={bottom}
            max={calcMax}
            text={text}
            colors={colors.bottom}
            isBelow
          />
        )}
      </div>
    </div>
  );
};

export default Stacked;
