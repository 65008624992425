import Header from "./components/Header";
import Container from "./components/Container";
import Content from "./components/Content";
import VizBlock from "./components/VizBlock";
import PageHeader from "./components/Page/Header/tj/";
import PageFooter from "./components/Page/Footer/tj/";
import Footer from "./components/Footer";
import content from "./content/UK.json";

const isTJ = true;

if (navigator.userAgent !== "ReactSnap") {
  window.analyticsService.sendViewEvent("GenderBiasInsightsView", {});
}

function App() {
  return (
    <div>
      {isTJ && <PageHeader />}

      <Header title={content.title} text={content.lead} nav={content.nav} />

      <Container>
        <Content items={content.content} />
      </Container>

      <div>
        <VizBlock {...content.biasedWords} />
        <VizBlock {...content.biasedSectors} />
        <VizBlock {...content.biasedDisciplines} />
        <VizBlock {...content.biasedSeniority} />
      </div>
      <Footer {...content.footer} />

      {isTJ && <PageFooter />}
    </div>
  );
}

export default App;
