import React from "react";
import s from "./style.module.css";
import Container from "../Container";
import Small from "../Small";
import cn from "classnames";
import t from "../../typography.module.css";

const isTJ = true;

const InfoBox = ({ title, source, items = {}, icons }) => {
  const allItems = Object.entries(items).map(([key, value]) => ({
    id: key,
    ...value,
  }));

  return (
    <div className={cn(s.wrap, { [s.isFirst]: title === "Did you know?" })}>
      <Container>
        <h2 className={cn(s.title, t.xl)}>{title}</h2>

        <div className={s.inner}>
          {allItems.map(({ title, text, title2, text2 }, i) => (
            <div key={i} className={s.item}>
              <img
                className={s.icon}
                src={`./static/images/${isTJ ? "TJ" : "SS"}/${icons[i]}`}
                alt=""
              />

              {title && (
                <p className={s.itemTitle}>
                  <strong>{title}</strong>
                </p>
              )}
              {text && <p className={s.itemText}>{text}</p>}

              {title2 && (
                <p className={s.itemTitle}>
                  <strong>{title2}</strong>
                </p>
              )}
              {text2 && <p className={s.itemText}>{text2}</p>}
            </div>
          ))}
        </div>
        {source && <Small>{source}</Small>}
      </Container>
    </div>
  );
};

export default InfoBox;
