import React, { useState } from "react";
import s from "./style.module.css";
import cn from "classnames";

const num = Math.floor(Math.random() * 3);
const Riddle = ({ riddles, question, cta }) => {
  const [answered, setAnswered] = useState(false);

  const riddle = riddles[num];

  return (
    <div className={s.wrap}>
      <p>{riddle.text}</p>
      <p>
        <strong>{question}</strong>
      </p>
      <button onClick={(e) => setAnswered(true)} className={s.button}>
        {cta}
      </button>
      <p
        className={cn(s.answer, {
          [s["answer--isVisible"]]: answered,
        })}
      >
        {riddle.answer}
      </p>
    </div>
  );
};

export default Riddle;
