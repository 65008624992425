import React from "react";
import s from "./style.module.css";
import Container from "../Container";
import Small from "../Small";
import Content from "../Content";
import cn from "classnames";
import t from "../../typography.module.css";

const Footer = ({ title, text, source, notes }) => {
  return (
    <div className={s.wrap}>
      <Container>
        <h2 className={cn(s.title, t.xl)}>{title}</h2>

        <Content items={text} />

        <p className={s.source}>
          <Small>
            <span dangerouslySetInnerHTML={{ __html: source }} />
          </Small>
        </p>
      </Container>
    </div>
  );
};

export default Footer;
