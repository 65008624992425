import React from "react";
import s from "./style.module.css";
import Container from "../../Container";

const Footer = ({ text }) => {
  return (
    <footer className={s.wrap}>
      <Container>
        <div className={s.footer}>
          {text && (
            <p>
              <strong>{text}</strong>
            </p>
          )}
          <div className={s.inner}>
            <div className={s.copy}>
              <p>© Totaljobs Group Ltd {new Date().getFullYear()}</p>
            </div>
            <div className={s.nav}>
              <a
                href="https://www.totaljobs.com/about/privacy-policy"
                className={s.link}
                rel="nofollow"
              >
                Privacy&nbsp;Policy
              </a>
              <a
                href="https://www.totaljobs.com/about/cookies"
                className={s.link}
                rel="nofollow"
              >
                Cookies
              </a>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
