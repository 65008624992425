import React from "react";
import s from "./style.module.css";
import { useInView } from "react-intersection-observer";

const PercentageWord = ({ data, fill, max = 35 }) => {
  const { ref, inView } = useInView({
    threshold: 0.3,
    delay: 30,
    triggerOnce: true,
  });

  return (
    <div
      ref={ref}
      data-inview={inView}
      className={s.wrap}
      style={{
        "--fill": fill,
      }}
    >
      {data.map(({ key, value }, i) => (
        <div className={s.row} key={i}>
          <div>
            <div className={s.word}>{key}</div>
          </div>
          <div
            className={s.bar}
            style={{
              "--offset": `${(value / max) * 100}%`,
              "--delay": `${i * 0.1}s`,
            }}
          >
            <strong>{value}%</strong>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PercentageWord;
