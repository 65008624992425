import React from "react";
import s from "./style.module.css";

const Button = ({ tag: Tag = "button", children, ...rest }) => {
  return (
    <Tag className={s.wrap} {...rest}>
      {children}
    </Tag>
  );
};

export default Button;
