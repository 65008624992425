import React from "react";
import s from "./style.module.css";
import { useInView } from "react-intersection-observer";

const PercentageBar = ({ data, fill }) => {
  const { ref, inView } = useInView({
    threshold: 0.3,
    delay: 30,
    triggerOnce: true,
  });

  return (
    <div
      data-inview={inView}
      ref={ref}
      className={s.wrap}
      style={{
        "--fill": fill,
      }}
    >
      {data.map(({ key, value }, i) => (
        <div
          className={s.bar}
          key={i}
          style={{
            "--offset": `${value}%`,
            "--delay": `${i * 0.1}s`,
          }}
        >
          <strong>{value}%</strong> {key}
        </div>
      ))}
    </div>
  );
};

export default PercentageBar;
