import React from "react";
import s from "./style.module.css";
import Container from "../../Container";
import { ReactComponent as Logo } from "./Totaljobs.svg";

const Header = () => {
  return (
    <header className={s.header}>
      <Container isLarge>
        <div className={s.container}>
          <a
            className={s.link}
            href="https://www.totaljobs.com/"
            aria-label="totaljobs.com"
            target="blank"
          >
            <Logo />{" "}
          </a>
        </div>
      </Container>
    </header>
  );
};

export default Header;
