import React from "react";
import s from "./style.module.css";
import cn from "classnames";
import t from "../../typography.module.css";

const Small = ({ children }) => {
  return <span className={cn(s.wrap, t.xs)}>{children}</span>;
};

export default Small;
