import React from "react";
import s from "./style.module.css";
import Button from "../Button";
import cn from "classnames";
import t from "../../typography.module.css";

const isTJ = true;

const Cta = ({ title, text, cta, url }) => {
  return (
    <div className={s.wrap}>
      <img
        className={s.image}
        src={`./static/images/${isTJ ? "TJ" : "SS"}/decoder.jpg`}
        alt=""
      />
      <div>
        <div className={s.copy}>
          <h2 className={cn(s.title, t.m)}>{title}</h2>
          <p className={s.text}>{text}</p>
          <p className={s.text}>
            <Button tag="a" href={url}>
              {cta}
            </Button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Cta;
