import React from "react";
import s from "./style.module.css";
import cn from "classnames";

const Container = ({
  children,
  isSmall,
  noPadding,
  noMargin,
  className,
  isLarge
}) => {
  return (
    <section
      className={cn(
        s.container,
        { [s.isSmall]: isSmall },
        { [s.isLarge]: isLarge },
        { [s.noPadding]: noPadding },
        { [s.noMargin]: noMargin },
        className
      )}
    >
      {children}
    </section>
  );
};

export default Container;
