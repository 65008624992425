import React, { Fragment } from "react";
import s from "./style.module.css";
import cn from "classnames";
import t from "../../typography.module.css";
import InfoBox from "../InfoBox";
import Riddle from "../Riddle";
import Stacked from "../Stacked";
import MultiStacked from "../MultiStacked";
import PercentageBar from "../PercentageBar";
import PercentageWord from "../PercentageWord";
import CTA from "../CTA";

import overall from "../../content/UK/overall_bias.json";
import jobAds from "../../content/UK/job_ads.json";

const isTJ = true;

const vizzes = {
  info1: {
    icons: ["1.1 Worse pad.svg", "1.2 Less senior.svg", "1.3 Earn less.svg"],
  },
  info2: {
    icons: [
      "2.1 Scale unbalance left.svg",
      "2.2 Scale balance.svg",
      "2.3 Scale unbalance right.svg",
    ],
  },
  overall_bias: {
    bottom: overall,
    colors: { bottom: ["#0E2576"] },
    max: 100,
  },
  job_ads: {
    top: jobAds.filter(({ category }) => !category.includes("strong")),
    bottom: jobAds.filter(({ category }) => category.includes("strong")),
    colors: {
      top: ["rgba(247, 124, 0, 0.4)", "#fff", "rgba(194, 218, 73, 0.4)"],
      bottom: ["#F77C00", "#C2DA49"],
    },
  },
};

if (isTJ) {
  vizzes.overall_bias.colors.bottom = ["#169B00"];
  vizzes.job_ads.colors.top = ["#FBDACB", "#fff", "#BFE5E5"];
  vizzes.job_ads.colors.bottom = ["#F06C30", "#009696"];
}

const Content = ({ items }) => {
  return (
    <>
      {items.map(({ type, value }, i) => (
        <Fragment key={i}>
          {type === "headline" && (
            <h2
              className={cn(s.title, t.xxl)}
              id={value.id}
              dangerouslySetInnerHTML={{ __html: value.text }}
            />
          )}
          {type === "text" && (
            <p
              className={s.text}
              dangerouslySetInnerHTML={{
                __html: value.replace(
                  "<a ",
                  '<a target="_blank" rel="noreferrer" '
                ),
              }}
            />
          )}

          {type === "infobox" && <InfoBox {...value} {...vizzes[value.id]} />}
          {type === "riddle" && <Riddle {...value} />}
          {type === "stacked" && <Stacked {...value} {...vizzes[value.id]} />}
          {type === "multistacked" && <MultiStacked {...value} />}
          {type === "percentageBar" && <PercentageBar {...value} />}
          {type === "percentageWord" && <PercentageWord {...value} />}
          {type === "cta" && <CTA {...value} />}
        </Fragment>
      ))}
    </>
  );
};

export default Content;
