import React from "react";
import s from "./style.module.css";
import Container from "../Container";
import Content from "../Content";
import Small from "../Small";
import cn from "classnames";
import seniority from "../../content/UK/seniority";

const isTJ = true;

const config = {
  biased_words_male: {
    type: "percentageWord",
    data: require("../../content/UK/biased_words_male"),
    fill: "#F77C00",
  },
  biased_words_female: {
    type: "percentageWord",
    data: require("../../content/UK/biased_words_female"),
    fill: "#C2DA49",
  },
  sectors_male: {
    type: "percentageBar",
    data: require("../../content/UK/sectors_male"),
    fill: "#F77C00",
  },
  sectors_female: {
    type: "percentageBar",
    data: require("../../content/UK/sectors_female"),
    fill: "#C2DA49",
  },
  disciplines_male: {
    type: "percentageBar",
    data: require("../../content/UK/disciplines_male"),
    fill: "#F77C00",
  },
  disciplines_female: {
    type: "percentageBar",
    data: require("../../content/UK/disciplines_female"),
    fill: "#C2DA49",
  },
  seniority: {
    type: "multistacked",
    last: true,
    items: ["management", "nonManagement", "entry"],
    top: [
      seniority.filter(
        (d) => d.seniority === "management" && d.type !== "part"
      ),
      seniority.filter(
        (d) => d.seniority === "nonManagement" && d.type !== "part"
      ),
      seniority.filter((d) => d.seniority === "entry" && d.type !== "part"),
    ],
    bottom: [
      seniority.filter(
        (d) => d.seniority === "management" && d.type === "part"
      ),
      seniority.filter(
        (d) => d.seniority === "nonManagement" && d.type === "part"
      ),
      seniority.filter((d) => d.seniority === "entry" && d.type === "part"),
    ],
    colors: {
      top: ["rgba(247, 124, 0, 0.4)", "#fff", "rgba(194, 218, 73, 0.4)"],
      bottom: ["#F77C00", "#C2DA49"],
    },
  },
};

if (isTJ) {
  config.biased_words_male.fill = "#F06C30";
  config.sectors_male.fill = "#F06C30";
  config.disciplines_male.fill = "#F06C30";

  config.biased_words_female.fill = "#009696";
  config.sectors_female.fill = "#009696";
  config.disciplines_female.fill = "#009696";

  config.seniority.colors.top = ["#FBDACB", "#fff", "#BFE5E5"];
  config.seniority.colors.bottom = ["#F06C30", "#009696"];
}

// const getStacked = (key, text, sectors, i) => ({
//   type: "stacked",
//   value: {
//     id: "seniority",
//     top: data.filter((d) => d.seniority === key && d.type !== "part"),
//     bottom: data.filter((d) => d.seniority === key && d.type === "part"),
//     text,
//     title: sectors[key],
//     small: true,
//     includeTopLabels: false,
//     includeBottomLabels: false,
//     includeNums: false,
//     includeInline: true,
//   },
// });

const Viz = ({ title, id, sub, type, ...rest }) => (
  <div>
    <div className={s.vizHeader}>
      <h4 className={s.vizTitle}>{title}</h4>
      {sub && <p className={s.vizSub}>{sub}</p>}
    </div>

    {type && <Content items={[{ type, value: rest }]} />}
  </div>
);

const VizBlock = ({ title, text, note, viz1, viz2 }) => {
  return (
    <div className={cn(s.wrap, { [s.last]: config[viz1.id].last })}>
      <Container>
        <h2 className={s.title}>{title}</h2>

        <Content items={text} />

        <div className={cn(s.vizWrap, { [s.vizWrapTwo]: viz2 })}>
          {viz1 && <Viz {...viz1} {...config[viz1.id]} />}
          {viz2 && <Viz {...viz2} {...config[viz2.id]} />}
          {note && (
            <div className={s.note}>
              <Small>{note}</Small>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default VizBlock;
