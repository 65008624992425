import React from "react";
import s from "./style.module.css";
import cn from "classnames";
import { Row as Legend } from "../Stacked";
import { useInView } from "react-intersection-observer";

const getOffset = (value, bottom, i) => {
  if (i === 0 && bottom.length) {
    return ((bottom[0].value + (value - bottom[0].value) / 2) / value) * 100;
  }

  if (i === 2 && bottom.length) {
    return ((value - bottom[bottom.length - 1].value) / 2 / value) * 100;
  }

  return 50;
};

export const Row = ({ items, max, colors, text, isBelow, offset, bottom }) => (
  <div className={s.bar}>
    {items.map(({ category, value }, i, arr) => (
      <div
        key={i}
        className={cn(s.section, {
          [s.isMale]: !category.toLowerCase().includes("female"),
        })}
        style={{
          flex: value / max,
          "--color": colors[i],
        }}
      >
        <span
          className={cn(s.label, {
            [s.labelIsTight]: value < 12,
            [s.labelIsTiny]: value < 6,
          })}
          style={{
            "--offset": bottom ? getOffset(value, bottom, i) : 50,
          }}
        >
          {value}%
        </span>
      </div>
    ))}
  </div>
);

const Stacked = ({
  title,
  items = [],
  top = [],
  bottom = [],
  colors,
  text,
  sectors,
  max = 100,
  includeNums = true,
  includeInline,
}) => {
  const calcMax = max || top.reduce((total, a) => a.value + total, 0);

  const { ref, inView } = useInView({
    threshold: 0.7,
    delay: 30,
    triggerOnce: true,
  });

  const leg = {
    top: [
      { category: "male", value: 2 },
      { category: "balanced", value: 1 },
      { category: "female", value: 2 },
    ],
    bottom: [
      { category: "strongMale", value: 1 },
      { category: "strongFemale", value: 1 },
    ],
  };

  return (
    <div className={s.wrap} ref={ref}>
      {title && <h3 className={s.title}>{title}</h3>}

      <div className={s.legend}>
        <Legend
          items={leg.top}
          text={text}
          colors={colors.top}
          max={5}
          hideNums
          small
        />
        <Legend
          items={leg.bottom}
          text={text}
          colors={colors.bottom}
          max={5}
          isBelow
          hideNums
          small
        />
      </div>
      <div data-inview={inView}>
        {items.map((key, i) => (
          <div className={s.row} key={i}>
            <div>{sectors[key]}</div>

            <div
              style={{
                "--delay": `${i * 0.25}s`,
              }}
            >
              {top[i] && (
                <Row
                  items={top[i]}
                  max={calcMax}
                  text={text}
                  colors={colors.top}
                  offset={50}
                  bottom={bottom[i]}
                />
              )}

              {bottom[i] && (
                <Row
                  items={bottom[i]}
                  max={calcMax}
                  text={text}
                  colors={colors.bottom}
                  isBelow
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Stacked;
